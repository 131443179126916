import React, { useState } from 'react'
import ServiceListItem from '../../components/services/serviceListItem'
import * as S from '../../styles/components/services/servicesStyle'

export default function ServiceList(props) {
  const [activeItem, setActiveItemData] = useState({
    title: '',
    itemHeight: '0px',
  })

  const selectItem = (itemTitle, elementHeight) => {
    if (itemTitle !== activeItem.title) {
      setActiveItemData({
        title: itemTitle,
        itemHeight: `${elementHeight + 90}px`,
      })
    } else {
      setActiveItemData({
        title: '',
        itemHeight: `0px`,
      })
    }
  }
  const listItems = props.services.map(elem => {
    return (
      <ServiceListItem
        key={elem.node.frontmatter.name}
        title={elem.node.frontmatter.name}
        text={elem.node.frontmatter.text}
        body={elem.node.html}
        activeItemHeight={
          elem.node.frontmatter.name === activeItem.title
            ? activeItem.itemHeight
            : '0px'
        }
        isOpen={elem.node.frontmatter.name === activeItem.title}
        selectItem={selectItem}
      />
    )
  })
  return <S.ListBody>{listItems}</S.ListBody>
}
