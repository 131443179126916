import React, { useRef } from 'react'
import * as S from '../../styles/components/services/servicesStyle'

export default function ServiceListItem(props) {
  const textContainer = useRef()

  return (
    <S.ListItem>
      <S.ListItemTitleContainer
        onClick={() =>
          props.selectItem(props.title, textContainer.current.offsetHeight)
        }
      >
        <S.ListItemTitle>{props.title}</S.ListItemTitle>
        <S.Arrow isOpen={props.isOpen} />
      </S.ListItemTitleContainer>
      <S.ListItemTextContainer activeItemHeight={props.activeItemHeight}>
        <S.ListItemText ref={textContainer}>
          <div dangerouslySetInnerHTML={{ __html: props.body }}></div>
        </S.ListItemText>
      </S.ListItemTextContainer>
    </S.ListItem>
  )
}
